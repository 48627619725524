@import "../../../node_modules/bootstrap/less/bootstrap.less";
@import "_variables.less";
@import "common/forms.less";
@import "components/buttons.less";
@import "axiforma";
@import "login-font";
@import "auth0-custom.less";

body {
  &:before {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-color: #f1f3f8;
    height: 100%;
    min-height: 100%;
  }

  .auth0-lock.auth0-lock .auth0-lock-widget {
    -webkit-animation: none !important;
    animation: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }

  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    max-height: none;
  }

  .auth0-lock.auth0-lock {
    display: block;
    font-family: "Helvetica Neue", "SF Pro Display", Arial, sans-serif;
    position: relative;
    bottom: auto;
    top: auto;
    height: auto;

    .auth0-lock-cred-pane {
      height: auto;
    }

    .auth0-lock-header-avatar {
      position: absolute;
      display: block;
      margin: 0;
      top: -90px;
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -40px;
      border: 7px solid rgba(255, 255, 255, 0.6);
    }

    .auth0-lock-input-wrap {
      border-color: transparent;

      &.auth0-lock-focused {
        border-color: transparent;

        .auth0-lock-input {
          border: 2px solid #0052cb;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }

      span {
        display: none;
      }

      &.auth0-lock-input-wrap-with-icon {
        padding: 0;
      }

      .auth0-lock-input {
        font-family: "Axiforma-Medium", sans-serif;
        border: 1px solid #9dabc5;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 1.2em;
        height: 48px;

        &::placeholder {
          font-family: "Axiforma-Medium", sans-serif;
          font-size: 14px;
          line-height: 22px;
          color: #7a8c92;
        }
      }
    }

    .auth0-global-message {
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 45px;
      border-radius: 4px;

      &.auth0-global-message-error {
        background: @brand-danger;
        color: #fff;
      }

      &.auth0-global-message-success {
        background: #e3fcef;
        border-radius: 8px;
        color: @ink-100-v2;

        .animated {
          display: flex;

          &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            background: url("../../../wwwroot/fe/img/verification-check-icon.svg")
              no-repeat;
            background-size: 100%;
          }

          span {
            flex: 1;
            text-align: left;

            &:before {
              content: "Password reset link is sent";
              font-family: "Axiforma-Bold", sans-serif;
              display: block;
              text-align: left;
              font-size: 16px;
              color: #021421;
              line-height: 22px;
            }
          }
        }
      }
    }

    .auth0-lock-submit {
      background: #0d1f23 !important;
      border-radius: 30px;
      height: 50px;
      align-self: center;
      width: 157px !important;
      padding: 0;
      letter-spacing: unset;

      .auth0-label-submit {
        font-family: "Axiforma-Medium", sans-serif;
        text-transform: none;
        font-size: 20px;
        height: auto;
        line-height: 20px;

        span {
          display: none;
        }
      }

      &:hover {
        background: #15c679 !important;

        &:disabled {
          background: #0d1f23 !important;
          opacity: 0.3;
          cursor: pointer;
          pointer-events: none;
        }
      }

      &:hover:not([disabled]) span {
        transform: none;
      }

      &:disabled {
        background: #0d1f23 !important;
        opacity: 0.3;
        cursor: pointer;
        pointer-events: none;
      }
    }

    .auth0-sso-notice-container {
      font-weight: 700;
      background: none;
      margin: 0;
    }

    .auth0-lock-content {
      padding: 0 0 20px 0;

      .auth0-lock-form {
        .auth0-lock-input-block {
          &.auth0-lock-input-show-password {
            margin-bottom: 16px !important;

            &:before {
              font-family: "Helvetica Neue", Arial, sans-serif;
              content: "Password*";
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #4c5a5d;
            }

            .auth0-lock-input-wrap-with-icon {
              height: 48px !important;
            }
          }

          &.auth0-lock-input-email {
            margin-bottom: 16px !important;

            &:before {
              font-family: "Helvetica Neue", Arial, sans-serif;
              content: "Email*";
              display: block;
              margin-bottom: 0.375rem;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #4c5a5d;
            }
          }

          &.auth0-lock-input-firstName {
            margin-bottom: 16px !important;
            &:before {
              font-family: "Helvetica Neue", Arial, sans-serif;
              content: "First name*";
              display: block;
              margin-bottom: 0.375rem;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #4c5a5d;
            }
          }

          &.auth0-lock-input-lastName {
            margin-bottom: 16px !important;
            &:before {
              font-family: "Helvetica Neue", Arial, sans-serif;
              content: "Last name*";
              display: block;
              margin-bottom: 0.375rem;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #4c5a5d;
            }
          }
        }

        .auth0-lock-alternative {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          text-align: right;

          .auth0-lock-alternative-link {
            font-family: "Helvetica Neue", Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: @blue-100;
          }
        }
      }
    }

    .auth0-lock-header-bg {
      display: none;
    }

    .auth0-lock-center {
      display: block;
    }

    .auth0-lock-overlay {
      display: none;
    }

    .auth0-lock-widget {
      width: 100%;
      padding: 0;
    }
  }
}

body.auth0page {
  #login-window {
    padding: 62px 32px 110px;

    &:before {
      height: 120px;
      background: none;
    }
  }
}

#login-window.slack-bot-installed,
#login-window.slack-linked {
  width: 520px;
  text-align: center;
  line-height: 22px !important;
  padding: 32px;

  &:before {
    height: 350px;
    margin: -45px -60px -90px;
  }

  a {
    color: #0052cb;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

body.auth0page.invitation {
  #login-window {
    padding: 32px !important;

    &:before {
      margin: -45px -60px 40px !important;
    }
  }

  .auth0-lock-header {
    display: none !important;
  }

  #invitation {
    top: -700px !important;
  }

  .bottom-content .gdpr-links {
    top: -15px !important;
  }
}

body.multiple-accounts,
body.multiple-sso-connections {
  #login-window {
    padding: 32px;

    &:before {
      height: 160px;
      content: "";
      background: none;
    }
  }

  #login-new-account {
    display: none;
  }

  .gdpr-links {
    top: 20px;
  }
}

body.no-user {
  #login-window {
    &:before {
      height: 320px;
      margin-bottom: -70px;
      content: "";
      background: url("../../../wwwroot/fe/img/quantive-icon-login.svg")
        no-repeat center center;
      display: block;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      .transition(0.3s);
    }
  }
}

body.auth0page.forgotten-password {
  #login-window {
    padding: 62px 32px 64px !important;

    &:before {
      height: 290px;
      margin-bottom: -70px;
      content: "";
      background: url("../../../wwwroot/fe/img/quantive-icon-reset-pass.svg")
        no-repeat center center !important;
      display: block;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      .transition(0.3s);
    }
  }

  .auth0-lock-content {
    padding: 0 !important;
  }

  .auth0-lock-name {
    margin-bottom: 0 !important;
    padding: 0 11px;
  }

  #login-new-account {
    display: none;
  }

  .gdpr-links {
    top: 15px;
  }

  .auth0-lock-submit {
    width: 253px !important;
    margin-top: 35px;
  }

  .auth0-lock.auth0-lock .auth0-lock-form p {
    margin-bottom: 20px;
  }

  .auth0-lock-input-email {
    margin-top: 32px !important;
  }
}

#login-window.logout {
  width: 436px;
  padding: 80px 32px 32px 32px;
}

#invitation {
  display: none;
  text-align: center;
  position: relative;
  color: @brand-jira;
  font-size: 1em;
  font-weight: 600;
  top: -730px;
}

#login-new-account {
  text-align: center;
  position: relative;
  top: -82px;

  .question {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #4c5a5d;
  }

  a {
    font-family: "Axiforma", sans-serif;
    font-weight: 510;
    font-size: 18px;
    line-height: 28px;
    color: #0052cb;

    &:hover {
      text-decoration: none;
    }

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 18px;
      margin-left: 0.375rem;
      background: url("../../../wwwroot/fe/img/arrow-right-blue-new.svg")
        no-repeat;
      background-size: 100%;
    }
  }
}

.bottom-content {
  text-align: center;
  width: 100%;

  a {
    font-family: "Axiforma-Medium", sans-serif;
    color: @brand-jira;
    font-size: 1em;
    display: inline-block;
    font-weight: @mediumbold;

    &.go-back {
      display: none;
    }
  }

  .gdpr-links {
    position: relative;
    margin-left: 20px;
    top: -35px;

    a {
      font-family: "Helvetica Neue", Arial, sans-serif;
      color: #0052cb;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      outline: none;
    }

    .privacy-policy {
      margin-right: 20px;
      line-height: 14px;
      padding-right: 20px;
      border-right: 1px solid #4c5a5d;
    }
  }
}

.working {
  #login-window {
    padding: 32px 32px;

    &:before {
      margin: 20px -60px -35px;
    }

    .loading {
      display: block;

      .gh-fg {
        margin-top: 10px;
      }

      .gh-fg-title {
        margin-bottom: 5px;
      }
    }
  }

  #login-new-account {
    display: none;
  }

  .gdpr-links {
    top: 20px;
  }
}

#login-window {
  filter: drop-shadow(0px 6px 12px rgba(68, 85, 115, 0.3));
  width: 452px;
  position: relative;
  background: #fff;
  margin: 0 auto 0;
  padding: 32px 32px 110px;
  box-shadow:
    0 6px 8px 2px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;

  .loading {
    display: none;
  }

  .multiple-accounts-content,
  .multiple-sso-connections-content {
    display: none;

    p {
      text-align: center;

      .link {
        font-size: 16px;
        line-height: 22px;
        color: #0052cb;

        &:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 16px;
          height: 18px;
          margin-right: 0.375rem;
          background: url("../../../wwwroot/fe/img/blue-arrow-left.svg")
            no-repeat;
          background-size: 100%;
        }
      }
    }
  }

  &.multiple-accounts {
    .multiple-accounts-content {
      display: block;
    }
  }

  &.multiple-sso-connections {
    .multiple-sso-connections-content {
      display: block;
    }
  }

  #multiple-accounts-list,
  #multiple-sso-connections-list {
    margin-bottom: 32px;
    list-style: none;
    padding: 0;

    button {
      font-family: "Helvetica Neue", Arial, sans-serif;
      background: #f1f3f8;
      border-radius: 4px;
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 28px;
      display: block;
      padding: 5px 20px;
      color: #0052cb;
      text-align: center;
      word-break: break-all;
      width: 100%;
      border: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  #multiple-sso-connections-list {
    a {
      word-break: break-all;
    }
  }

  .gh-fe {
    display: none;
  }

  .form {
    display: none;
  }

  &.enter-email {
    .form {
      display: block;
    }
  }

  &.no-user {
    .no-user-content {
      display: block;
    }
  }

  h3 {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.4em;
  }

  .no-user-content {
    display: none;

    .gh-b {
      background: #0d1f23;
      border-radius: 28px;
      margin: 32px 32px 16px;
      width: 320px;
      font-family: "Axiforma-Medium", sans-serif;
      font-size: 20px;
      height: 52px;
      line-height: 20px;
      align-items: center;
      color: #ffffff;

      &:hover {
        background: #15c679;
      }
    }

    h4 {
      font-family: "Axiforma-Bold", sans-serif;
      margin-bottom: 32px;
      font-size: 26px;
      line-height: 34px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #51617a;
      word-break: break-word;
    }

    p {
      font-family: "Helvetica Neue", Arial, sans-serif;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #51617a;
      margin: 0;
    }

    #email-placeholder {
      color: @ink-100-v2;
    }

    hr {
      border-top: 1px solid #d7e4ef !important;
      margin: 16px 0 0 0;
      position: relative;
      width: 70%;
      left: 15%;
    }
  }

  p {
    font-size: 1.1em;
  }

  .our-support {
    outline: none;
    text-decoration: underline;
  }

  .link {
    color: @brand-jira;
    font-weight: bold;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &:before {
    height: 210px;
    content: "";
    background: url("../../../wwwroot/fe/img/quantive-icon-login.svg") no-repeat
      center center;
    display: block;
    margin: -45px -60px -35px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    .transition(0.3s);
  }

  &.logout {
    &:before {
      height: 170px;
      content: "";
      background: url("../../../wwwroot/fe/img/quantive-icon-sign-out.svg")
        no-repeat center center;
      display: block;
      margin: -20px -60px 0;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      .transition(0.3s);
    }
  }

  .gh-b {
    font-family: "Axiforma-Medium", sans-serif;
    margin-top: 32px;
    background: #0d1f23;
    border-radius: 30px;
    color: @grey-10-v2;
    width: 143px;
    font-style: normal;
    font-weight: 510;
    font-size: 18px;
    line-height: 24px;
    height: 46px;
    margin-left: auto;
    margin-right: auto;
    display: block;

    &:hover {
      background: #15c679;
    }

    &:focus {
      box-shadow:
        0px 0px 0px 1px @grey-10-v2,
        0px 0px 0px 2px @blue-80-v2;
    }
  }

  &.login-window-email {
    &.enter-email:before {
      height: 120px;
      margin-top: 75px;
    }

    .gh-fg-title {
      font-family: "Axiforma-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 32px;
      letter-spacing: -0.01em;
      color: @ink-100;
    }

    legend {
      font-family: "Aeonik-Medium", sans-serif;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      margin-bottom: 32px;
      font-size: 32px;
      line-height: 34px;
      letter-spacing: -0.01em;
      color: @ink-100;
      border-bottom: none;
    }

    fieldset {
      margin-top: 60px;
      .gh-input-text {
        border: 1px solid @grey-20;
        height: 48px;

        &:hover {
          border: 1px solid @grey-40;
        }
        &:focus {
          border: 1px solid @blue-80-v2;
        }

        &::placeholder {
          font-family: "Axiforma-Medium", sans-serif;
          font-weight: normal;
          font-size: 16px;
          font-style: normal;
          color: @black-70;
        }
      }
    }

    .gh-fg {
      padding: 0;
      border: 0;
      margin-top: 60px;

      .gh-input-text {
        font-family: "Axiforma-Medium", sans-serif;
        font-style: normal;
        font-weight: normal;
        border: 1px solid @grey-30;
        box-sizing: border-box;
        border-radius: 4px;
        color: @ink-100-v2;
        font-size: 1.2em;
        height: 48px;

        &:focus {
          border: 2px solid #0052cb;
          box-sizing: border-box;
          border-radius: 4px;
        }

        &::placeholder {
          font-family: "Axiforma-Medium", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #626f74;
        }
      }
    }

    .gh-fe {
      font-family: "Helvetica Neue", Arial, sans-serif;
      color: #eb597b;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
    }

    .input-wrapper {
      &.error {
        input {
          border: 1px solid #ffb1c4;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }

      label {
        font-family: "Helvetica Neue", Arial, sans-serif;
        content: "Email*";
        display: block;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        color: #4c5a5d;
        margin-bottom: 0;
      }
    }

    #login-new-account a {
      margin-top: 3px;
    }
  }
}

#logo {
  width: 180px;
  position: relative;
  margin: 40px auto;
  z-index: 2;
  top: 112px;
  height: 32px;
  background: url("../../../wwwroot/fe/img/quantive-results-logo.svg") no-repeat
    left top;

  .formerly-text {
    position: relative;
    top: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #626f74;
  }
}

.mk-spinner-wrap {
  position: relative;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.indicator-small {
  position: relative;
  height: 35px;
  line-height: 35px;
  margin: 5px auto;
  width: 35px;
}

.mk-spinner-pie {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin-top: 7px;
  margin-left: 5px;
  z-index: 1;
  background: transparent;
  border-radius: 50%;
  border: 5px solid;
  border-color: #cae7b9 #f3de8a #eb9486 #7e7f9a;
  -webkit-animation: a 1s infinite linear;
  animation: a 0.4s infinite linear;
}

.slack-install-whats-next {
  text-align: center;
  padding-bottom: 28px;
  h3 {
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }
}

.instructions-list {
  text-align: left;
  h3 {
    font-size: 16px !important;
    padding-bottom: 8px;
    font-weight: 700;
    line-height: 22px !important;
  }
  ol {
    font-size: 14px !important;
    padding-left: 1.3em;
    font-weight: 400;
  }
}

.help-articles {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  text-align: left;
  h3 {
    font-size: 14px !important;
    line-height: 22px !important;
    padding-bottom: 8px;
    font-weight: 400 !important;
  }
  a {
    font-size: 14px !important;
    padding-bottom: 8px;
    font-weight: 510 !important;
  }
}

.logout-success-message {
  position: relative;
  top: -10px;
  font-family: "Aeonik-Medium", sans-serif;
  text-align: center;
  color: @ink-100;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;

  div {
    margin: 2px 0 28px 0;
  }
}

#changed-your-mind {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 26px;
  color: @brand-grey2;
  margin: 2px 0;
  text-align: center;
}

#login-link {
  font-family: "Axiforma-Bold", sans-serif;
  display: block;
  text-align: center;
  margin: 0 4px;
  color: #0052cb;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 18px;
    margin-left: 0.375rem;
    background: url("../../../wwwroot/fe/img/arrow-right-blue-new.svg")
      no-repeat;
    background-size: 100%;
  }
}

.multiple-accounts-content,
.multiple-sso-connections-content {
  h4 {
    font-family: "Axiforma-Bold", sans-serif;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
  }

  .subtext {
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #51617a;
    margin: 11px 0 9px;
  }

  .link {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    color: #0052cb !important;
    margin: 3px 0 0 0;

    &:hover {
      text-decoration: none !important;
    }

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 18px;
      margin-right: 0.375rem;
      background: url("../../../wwwroot/fe/img/blue-arrow-left.svg") no-repeat;
      background-size: 100%;
    }
  }

  p {
    margin-bottom: 0;
  }

  .different-email {
    text-align: center;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
}

#quantive-logo {
  width: 120px;
  position: relative;
  margin: 40px auto;
  z-index: 2;
  top: 112px;
  height: 32px;
  background: url("../../../wwwroot/fe/img/quantive-logo.svg") no-repeat left
    top;

  .formerly-text {
    position: relative;
    top: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #626f74;
  }
}

.auth-error {
  width: 436px;
  min-height: 320px;
  position: relative;
  margin: 0 auto 0;
  padding: 32px 32px 42px 32px;
  background: #ffffff;
  box-shadow:
    0 6px 8px 2px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;

  img {
    margin: auto;
    display: block;
    top: 80px;
    position: relative;
  }

  .auth-error-content {
    position: relative;
    top: 50px;
    text-align: center;
    margin-top: 40px;

    .error-title {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      color: #0d232f;
      margin-bottom: 32px;
    }

    .auth-error-title {
      font-family: "Aeonik", sans-serif;
      font-style: normal;
      color: @ink-100;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 4px;
    }

    .auth-error-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7a8c92;
      word-break: break-word;
    }

    .tracking {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #0d1f23;
      background: #e0eeff;
      border-radius: 4px;
      padding: 12px 16px;
      margin-top: 16px;
    }
  }

  #support {
    position: relative;
    display: block;
    text-align: center;
    color: #626f74;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    top: 106px;

    a {
      color: #0052cb;
    }
  }
}

@media all and (max-width: 480px) {
  body {
    &.forgotten-password {
      .auth0-lock.auth0-lock .auth0-lock-cred-pane {
        height: 275px;
      }
    }

    .auth0-lock.auth0-lock {
      &.auth0-lock-with-tabs {
        .auth0-lock-cred-pane-internal-wrapper {
          max-height: 520px !important;
        }

        &.auth0-lock-with-terms {
          .auth0-lock-cred-pane-internal-wrapper {
            max-height: 725px !important;
          }
        }
      }

      .auth0-lock-cred-pane-internal-wrapper {
        max-height: 490px !important;
      }
    }

    &.auth0page.invitation {
      #invitation {
        top: -715px !important;
      }

      .auth0-lock.auth0-lock {
        &.auth0-lock-with-tabs.auth0-lock-with-terms {
          .auth0-lock-cred-pane-internal-wrapper {
            max-height: 635px !important;
          }
        }
      }
    }

    #login-window {
      width: 360px;
    }

    .auth0-lock .auth0-loading-screen {
      top: calc(15vh - 100%) !important;
      left: calc(50vw - 60px) !important;
    }
  }
}
